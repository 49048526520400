import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Card, Badge, Tooltip } from "antd";
import { render } from "react-dom";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import styled from "styled-components";
import HowItWorksImage1 from "../static/Home/how-it-works-img-1.svg";
import HowItWorksImage2 from "../static/Home/how-it-works-img-2.svg";
import HowItWorksImage3 from "../static/Home/how-it-works-img-3.svg";
import HowItWorksImage4 from "../static/Home/how-it-works-img-4.svg";
import ProductImage1 from "../static/Home/product-image-1.png";
import ProductImage2 from "../static/Home/product-image-2.png";
import ProductImage3 from "../static/Home/product-image-3.png";
import GalleryHome from "../static/Home/gallery-home-img.png";
import CircleWhite from "../static/Home/circle-white.svg";
import GalleryHomeBack from "../static/Home/gallery-home-back-img.png";
import LadyImage from "../static/Home/LADY_Header.png";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ContactBack from "../static/Home/contact-back.png";
// import WingRight from "../static/Home/wing-right.png";
import Eifel from "../static/Home/eifel-img.png";
import LogoModal from "../static/Home/main-logo.svg";
import EifelMobile from "../static/Home/eifel-img-mobile.png";
import HoriLine from "../static/Home/hori-line.svg";
import Circle1 from "../static/Home/circles-1.svg";
import X1 from "../static/Home/x-1.svg";
import CircleLine from "../static/Home/circle-line.svg";
import VertiLine from "../static/Home/verti-line.svg";
import plusImg from "../static/Home/plus-img.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";
const { TextArea } = Input;

const StyledInput = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.2em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  height: 45px !important;
  padding: 4px 0px ;
  background-color: white!important;
  background-image: none;
  border: none;
  border-bottom: 0px solid white;
  // border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: black!important;
  // box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-bottom: 0px solid red !important;
  }
  :active{
    border-bottom: 0px red !important;
  }
  @media only screen and (max-width: 600px) {
  width:100vw;
  margin:auto;
    }
  }
`;

const { Meta } = Card;
const Title = () => (
  <Fade bottom>
    <h1
      className="mulish-header"
      style={{
        marginTop: "3em",
        fontWeight: 700,
        fontSize: 55,
        marginLeft: "0em",
        color: "white",
        lineHeight: "1.2em",
      }}
    >
      A revolutionary way to <br />
      settle an insurance claim
    </h1>{" "}
    <p
      className="mulish-paragraph-small"
      style={{
        fontSize: 18,
        marginTop: "1.5rem",
        color: "white",
      }}
    >
      Replace goods and services directly from approved retailers
    </p>{" "}
    <div className="home-button">
      <a href="#contact-us">
        <Button
          style={{
            borderRadius: 50,
            fontWeight: 500,
            height: "3em",
            width: "11em",
            fontSize: "17px",
            backgroundColor: "#4A84B8",
            border: "black",
            marginTop: "1em",
          }}
          type="primary"
        >
          Contact Us
        </Button>
      </a>
    </div>
  </Fade>
);

const ProductsContent = () => (
  <div
    style={{
      width: "100vw",
      backgroundColor: "#F0F0F0",
      paddingBottom: "3em",
    }}
  >
    <Row style={{ maxWidth: "90em", margin: "auto" }}>
      <Col sm={24} md={8}>
        <Card
          style={{
            margin: "1em",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            textAlign: "center",
            backgroundColor: "transparent",
          }}
          // hoverable
          cover={
            <img
              className="products-img"
              style={{
                margin: "auto",
                marginTop: "4em",
                width: "5em",
                textAlign: "center",
              }}
              src={ProductImage1}
            ></img>
          }
        >
          <Meta
            title="Our Story"
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  The program was launched in 2011, by Thesl in partnership with
                  other leading companies. Today the program is managed
                  holistically by Thesl in collaboration with third-party
                  service providers.
                </p>{" "}
              </span>
            }
          />
        </Card>
      </Col>
      <Col sm={24} md={8}>
        <Card
          style={{
            margin: "1em",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            textAlign: "center",
            backgroundColor: "transparent",
          }}
          // hoverable
          cover={
            <img
              className="products-img-1"
              style={{
                margin: "auto",
                marginTop: "4em",
                width: "5em",
                textAlign: "center",
              }}
              src={ProductImage2}
            ></img>
          }
        >
          <Meta
            title="How we do it"
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  Claims are paid directly into your ClaimsCard so that you can
                  replace goods and services directly from approved retailers.
                </p>{" "}
              </span>
            }
          />
        </Card>
      </Col>
      <Col sm={24} md={8}>
        <Card
          style={{
            margin: "1em",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            textAlign: "center",
            backgroundColor: "transparent",
          }}
          // hoverable
          cover={
            <img
              className="products-img-1"
              style={{
                margin: "auto",
                marginTop: "4em",
                width: "5em",
                textAlign: "center",
              }}
              src={ProductImage3}
            ></img>
          }
        >
          <Meta
            title="Quick Contact"
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  <a href="tel:+27861252467" style={{ color: "black" }}>
                    {" "}
                    Telephone: 0861 CLAIMS
                  </a>
                  <br />
                  <a href="tel:+27861252467" style={{ color: "black" }}>
                    {" "}
                    (086 125 2467)
                  </a>
                  <br />
                  <br />
                  <a
                    href="mailto:support@claimscard.co.za"
                    style={{ color: "black" }}
                  >
                    {" "}
                    Email: support@claimscard.co.za
                  </a>
                </p>{" "}
              </span>
            }
          />
        </Card>
      </Col>
    </Row>
  </div>
);
const HowItWorksImg1 = () => (
  <Col sm={24} md={12}>
    <img
      src={Circle1}
      style={{
        width: "3em",
        position: "absolute",
        marginRight: "0.2em",
        marginLeft: "0.2em",
        marginTop: "-0.3em",
      }}
    ></img>
    <img
      className="home-img"
      style={{
        margin: "auto",
        marginTop: "4em",
        textAlign: "center",
        width: "375px",
        marginLeft: "5em",
      }}
      src={HowItWorksImage1}
    ></img>{" "}
    <img
      src={CircleLine}
      style={{
        width: "3.5em",
        right: "-1em",
        bottom: "-5em",
        position: "absolute",
        marginRight: "0.2em",
        marginLeft: "0.2em",
        marginTop: "-0.3em",
      }}
    ></img>
  </Col>
);
const HowItWorksParagraph1 = () => (
  <div>
    <img
      src={X1}
      style={{
        width: "2em",
        right: "9em",
        top: "-3em",
        position: "absolute",
        marginRight: "0.2em",
        marginLeft: "0.2em",
        marginTop: "-0.3em",
      }}
    ></img>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 800,
        fontSize: "40px",
      }}
    >
      Gives you power
    </h1>
    <p
      className="Mulish"
      style={{
        fontSize: 16,
        lineHeight: "1.5em",
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      ClaimsCard gives your purchasing <br />
      power back to you so that you can
      <br />
      replace lost, stolen or damaged goods
      <br />
      as per the approved claims category,
      <br />
      when you want, and at the retailers
      <br /> listed for the item category.
    </p>{" "}
    <img
      src={plusImg}
      style={{
        width: "2.3em",
        right: "27em",
        bottom: "-10em",
        position: "absolute",
        marginRight: "0.2em",
        marginLeft: "0.2em",
        marginTop: "-0.3em",
      }}
    ></img>
    <img
      src={X1}
      style={{
        width: "2em",
        right: "33em",
        bottom: "-12em",
        position: "absolute",
        marginRight: "0.2em",
        marginLeft: "0.2em",
        marginTop: "-0.3em",
      }}
    ></img>
  </div>
);
const HowItWorksImg2 = () => (
  <img
    className="home-img"
    style={{
      margin: "auto",
      marginTop: "4em",
      textAlign: "center",
      width: "23em",
    }}
    src={HowItWorksImage2}
  ></img>
);
const HowItWorksParagraph2 = () => (
  <div>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 800,
        fontSize: "40px",
      }}
    >
      Get Card Balance
    </h1>
    <p
      className="Mulish"
      style={{
        fontSize: 16,
        lineHeight: "1.5em",
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      You can obtain your card balance by:
      <br />
      <br />
      SMS'ing your card number to 43737 <br />- Standard cellphone rates apply.
      <br />
      <br />
      Visiting:{" "}
      <a
        href="https://www.whatsonmycard.com/"
        target="_blank"
        style={{ color: "black" }}
      >
        www.whatsonmycard.com
      </a>
      <br />
      <br />
      <a href="tel:+27861252467" style={{ color: "black" }}>
        {" "}
        Phoning: 0861 252467 (CLAIMS)
      </a>
    </p>{" "}
  </div>
);
const HowItWorksImg3 = () => (
  <Col sm={24} md={12}>
    <img
      className="home-img"
      style={{
        margin: "auto",
        marginTop: "4em",
        textAlign: "center",
        width: "27em",
        marginLeft: "5em",
      }}
      src={HowItWorksImage3}
    ></img>{" "}
  </Col>
);
const HowItWorksParagraph3 = () => (
  <div>
    <img
      src={CircleLine}
      style={{
        width: "3.5em",
        transform: "rotate(86deg)",
        right: "26em",
        top: "-5em",
        position: "absolute",
        marginRight: "0.2em",
        marginLeft: "0.2em",
        marginTop: "-0.3em",
      }}
    ></img>
    <p
      className="Mulish"
      style={{
        fontSize: 16,
        lineHeight: "1.5em",
        marginTop: "2rem",
        color: "black",
      }}
    >
      Your card is protected by a PIN code <br />
      which must be kept secret at all times.
      <br /> Do not write this number down or
      <br /> reveal it to anybody.
      <br />
      Do not let the card out of your sight
      <br /> when paying for goods or services or
      <br /> give the card number and expiry <br />
      information to anybody.
    </p>{" "}
    <img
      src={Circle1}
      style={{
        right: "6em",
        bottom: "-3em",
        width: "2.5em",
        position: "absolute",
        marginRight: "0.2em",
        marginLeft: "0.2em",
        marginTop: "-0.3em",
        transform: "rotate(-130deg)",
      }}
    ></img>
  </div>
);
const HowItWorksImg4 = () => (
  <Col sm={24} md={12}>
    <img
      className="home-img"
      style={{
        margin: "auto",
        marginTop: "4em",
        textAlign: "center",
        width: "27em",
      }}
      src={HowItWorksImage4}
    ></img>{" "}
  </Col>
);
const HowItWorksParagraph4 = () => (
  <div>
    <img
      src={plusImg}
      style={{
        width: "2.3em",
        right: "6em",
        top: "-7em",
        position: "absolute",
        marginRight: "0.2em",
        marginLeft: "0.2em",
        marginTop: "-0.3em",
      }}
    ></img>
    <img
      src={X1}
      style={{
        width: "2em",
        right: "2em",
        top: "-2em",
        position: "absolute",
        marginRight: "0.2em",
        marginLeft: "0.2em",
        marginTop: "-0.3em",
      }}
    ></img>
    <p
      className="Mulish"
      style={{
        fontSize: 16,
        lineHeight: "1.5em",
        marginTop: "2rem",
        color: "black",
      }}
    >
      Should you lose your ClaimsCard stop
      <br /> it immediately by phoning: <br />
      0861 CLAIMS{" "}
      <a href="tel:+27861252467" style={{ color: "black" }}>
        (0861252467)
      </a>
      .
      <br />
      <br />
      If you suspect any unauthorised
      <br /> transactions on you account please
      <br /> check your balance immediately and
      <br /> should there be any unauthorised
      <br /> transactions please phone:
      <br />
      0861 CLAIMS{" "}
      <a href="tel:+27861252467" style={{ color: "black" }}>
        (0861252467)
      </a>
      .
    </p>{" "}
  </div>
);
const GalleryHeader = () => (
  <div>
    <img
      className="gallery-banner-img"
      style={{ margin: "auto", textAlign: "center" }}
      src={GalleryHome}
    ></img>{" "}
  </div>
);
const GalleryParagraph = () => (
  <div style={{}} className="gallery-banner">
    <p
      className="Mulish"
      style={{
        whiteSpace: "nowrap",
        width: "100%",
        textAlign: "center",
        fontWeight: 500,
        fontSize: "69px",
        marginBottom: "-0em",
        lineHeight: 1.3,
        color: "white",
      }}
    >
      Become a Retailer
    </p>
    <div style={{ width: "100%", textAlign: "center" }}>
      <img
        style={{
          margin: "auto",
          marginTop: "1em",
          marginBottom: "1em",
          width: "3em",
          textAlign: "center",
        }}
        src={HoriLine}
      ></img>
    </div>
    <p
      className="Mulish"
      style={{
        whiteSpace: "nowrap",
        fontSize: 16,
        fontWeight: 400,
        marginTop: "1.5rem",
        color: "white",
      }}
    >
      Want to become a listed retailer?
      <br />
      If you want to become listed as a retailer so that claimants may purchase
      goods or <br />
      services from you please send an email to{" "}
      <a href="mailto:support@claimscard.co.za" style={{ color: "white" }}>
        support@claimscard.co.za
      </a>
      .
      <br />
      <br />
      Client service is extremely important to us so retailers are approved
      beforehand.
      <br />
      We will inspect your premises and ensure that you are able to offer the
      required service <br />
      to our clients.
    </p>{" "}
  </div>
);
const ContactHeader = () => (
  <div id="contact-us" style={{ textAlign: "left" }}>
    <p
      className="Mulish"
      style={{
        fontSize: 20,
        fontWeight: 700,
        marginTop: "1.5rem",
        color: "white",
      }}
    >
      <img
        src={CircleWhite}
        style={{
          width: "1.2em",
          marginRight: "0.2em",
          marginTop: "-0.3em",
        }}
      ></img>
      {"    "}
      Contact Us
    </p>{" "}
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 700,
        fontSize: "40px",
        color: "white",
      }}
    >
      Contact us now to become a ClaimsCard partner!
    </h1>
  </div>
);
const HowItWorksHeader = () => (
  <div>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 800,
        lineHeight: "1.5em",
        fontSize: "40px",
      }}
    >
      How it works
    </h1>
  </div>
);
const HowItWorksHeader2 = () => (
  <div>
    <h1
      className="Mulish"
      style={{
        margin: "auto",
        fontWeight: 800,
        fontSize: "40px",
      }}
    >
      Safety Tips
    </h1>
  </div>
);
//const ContactForm = () => (
//  <Form
//    onFinish={this.handleSubmit}
//    initialValues={{
//      remember: true,
//    }}
//    name="wrap"
//    labelCol={{ flex: "110px" }}
//    labelAlign="left"
//    labelWrap
//    wrapperCol={{ flex: 1 }}
//    colon={false}
//  >
//    <Row style={{}}>
//      <Col sm={24} md={11}>
//        <Form.Item name="name" rules={[{ required: true }]}>
//          <StyledInput placeholder="Company Name" />
//        </Form.Item>
//      </Col>
//      <Col sm={0} md={2}></Col>
//      <Col sm={24} md={11}>
//        <Form.Item name="email" rules={[{ required: true }]}>
//          <StyledInput placeholder="Company Email" />
//        </Form.Item>
//      </Col>
//      <Col sm={24} md={24}>
//        <Form.Item name="message" rules={[{ required: true }]}>
//          <StyledInput placeholder="Message" />
//        </Form.Item>
//      </Col>
//    </Row>
//    <Form.Item>
//      <Button
//        style={{
//          borderRadius: 50,
//          fontWeight: 500,
//          height: "3em",
//          width: "9em",
//          fontSize: "17px",
//          backgroundColor: "#D63629",
//          border: "black",
//          marginTop: "1em",
//        }}
//        type="primary"
//        htmlType="submit"
//      >
//        Send
//      </Button>
//    </Form.Item>
//  </Form>
//);
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successModal: false,
    };
    this.scrollToTop = this.scrollToTop.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register("end", () => {
        resolve();
        Events.scrollEvent.remove("end");
      });

      scroller.scrollTo("scroll-container", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo("scroll-container-second-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "scroll-container",
      })
    );
  }
  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }
  handleTabClick = key => {
    this.props.history.push(`/${key}`); // < == router router v4
  };
  componentDidMount() {
    document.title = "ClaimsCard";
    document.documentElement.scrollTop = 0;
  }
  onChange = e => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = value => {
    this.setState({
      inputValue: value,
    });
  };
  showModal = () => {
    this.setState({
      visiblePopup: true,
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };
  handleClickDemo(form) {
    this.setState({
      FormChangeDemo: form,
    });
  }
  handleClickCall(form) {
    this.setState({
      FormChangeCall: form,
    });
  }
  showModalDrone = () => {
    this.setState({
      visiblePopupDrone: true,
    });
  };
  handleOk1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };

  handleCancel1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };
  handleSubmit = values => {
    this.onFinish(values);
  };
  changeValue = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };

  onFinish = values => {
    const MSG = {
      to: ["support@claimscard.co.za"],
      from: "info@claimscard.co.za",
      //to: ["ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New contact request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/5TK430S/CC-copy.jpg" width="300" height="100" style="display: block; width:146px; max-width: 355px;; min-width: 146px;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new contact request from <br /> ClaimsCard website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br /><br /><br /><br /><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.nameMobile}<br />
                           
                               <strong> Email </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.emailMobile}<br />
                               <strong> Message </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.messageMobile}
                             <br /><br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2023@ClaimsCard</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async res => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        nameMobile: "",
        emailMobile: "",
        messageMobile: "",
      });
    });
  };

  render() {
    return (
      <div id="home">
        <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container-fluid">
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <Link
                activeClass="active"
                className="test1"
                to="home"
                spy={true}
                smooth={true}
                duration={500}
                style={{
                  position: "fixed",
                  zIndex: 10,
                  right: "1em",
                  top: "46vh",
                }}
              >
                <div className="badge-class">
                  <Badge color="#4A84B8"></Badge>
                </div>
              </Link>
              <Link
                activeClass="active"
                className="test1"
                to="about"
                spy={true}
                smooth={true}
                duration={500}
                style={{
                  position: "fixed",
                  zIndex: 10,
                  right: "1em",
                  top: "50vh",
                }}
              >
                <div className="badge-class">
                  <Badge color="#4A84B8"></Badge>
                </div>
              </Link>
              <Link
                activeClass="active"
                className="test1"
                to="how-it-works"
                spy={true}
                smooth={true}
                duration={500}
                style={{
                  position: "fixed",
                  zIndex: 10,
                  right: "1em",
                  top: "54vh",
                }}
              >
                <div className="badge-class">
                  <Badge color="#4A84B8"></Badge>
                </div>
              </Link>
              <Link
                activeClass="active"
                className="test1"
                to="retailers"
                spy={true}
                smooth={true}
                duration={500}
                style={{
                  position: "fixed",
                  zIndex: 10,
                  right: "1em",
                  top: "58vh",
                }}
              >
                <div className="badge-class">
                  <Badge color="#4A84B8"></Badge>
                </div>
              </Link>
              <Link
                activeClass="active"
                className="test1"
                to="contact-us"
                spy={true}
                smooth={true}
                duration={500}
                style={{
                  position: "fixed",
                  zIndex: 10,
                  right: "1em",
                  top: "62vh",
                }}
              >
                <div className="badge-class">
                  <Badge color="#4A84B8"></Badge>
                </div>
              </Link>
            </div>
          </div>
        </nav>
        <img
          className="eifeil-img"
          src={Eifel}
          style={{
            position: "absolute",
            objectFit: "cover",
            top: 0,
            left: 0,

            zIndex: -1,
            height: "58.7em",
            width: "100vw",
          }}
        ></img>

        <Element name="home" className="element">
          <Row
            style={{ maxWidth: "90em", margin: "auto", textAlign: "center" }}
          >
            <Col sm={24} md={24} className="home-col">
              <Title />
            </Col>
            <Col sm={24} md={24}>
              <img
                className="home-img"
                style={{
                  margin: "auto",
                  paddingTop: "4em",
                  textAlign: "center",
                  width: "30em",
                }}
                src={LadyImage}
              ></img>{" "}
            </Col>
          </Row>
        </Element>
        <Element name="about" className="element">
          <div
            id="about"
            style={{ paddingTop: "5em", backgroundColor: "white" }}
          ></div>
          <ProductsContent />
        </Element>
        <Element name="how-it-works" className="element">
          <div id="how-it-works" style={{ backgroundColor: "white" }}>
            <Row
              style={{
                maxWidth: "70em",
                margin: "auto",
                textAlign: "center",
                paddingTop: "7em",
              }}
            >
              <Col sm={24} md={24}>
                <HowItWorksHeader />
              </Col>
            </Row>
            <Row
              className="about-row-cert"
              style={{
                maxWidth: "90em",
                margin: "auto",
                marginTop: "5em",
              }}
            >
              <Col
                sm={24}
                md={5}
                style={{ margin: "auto", marginTop: "-3.5em" }}
              >
                <HowItWorksImg1 />
              </Col>
              <Col sm={24} md={8} style={{ margin: "auto" }}>
                <HowItWorksParagraph1 />
              </Col>
            </Row>
            <Row
              className="about-row-cert"
              style={{
                maxWidth: "65em",
                margin: "auto",
                marginTop: "14em",
              }}
            >
              <Col sm={0} md={2}></Col>
              <Col sm={24} md={10} style={{ margin: "auto" }}>
                <HowItWorksParagraph2 />
              </Col>
              <Col sm={0} md={2}></Col>
              <Col
                sm={24}
                md={10}
                style={{ margin: "auto", marginTop: "-5em" }}
              >
                <HowItWorksImg2 />
              </Col>
            </Row>
            <Row
              style={{
                maxWidth: "70em",
                margin: "auto",
                textAlign: "center",
                marginTop: "5em",
              }}
            >
              <Col sm={24} md={24}>
                <HowItWorksHeader2 />
              </Col>
            </Row>
            <Row
              className="about-row-cert"
              style={{
                maxWidth: "90em",
                margin: "auto",
                marginTop: "4em",
              }}
            >
              <Col
                sm={24}
                md={5}
                style={{ margin: "auto", marginTop: "-3.5em" }}
              >
                <HowItWorksImg3 />
              </Col>
              <Col sm={24} md={8} style={{ margin: "auto" }}>
                <HowItWorksParagraph3 />
              </Col>
            </Row>
            <Row
              className="about-row-cert"
              style={{
                maxWidth: "60em",
                margin: "auto",
                marginTop: "7em",
                paddingBottom: "3em",
              }}
            >
              <Col sm={0} md={1}></Col>
              <Col sm={24} md={11} style={{ margin: "auto" }}>
                <HowItWorksParagraph4 />
              </Col>
              <Col
                sm={24}
                md={12}
                style={{ margin: "auto", marginTop: "-5em" }}
              >
                <HowItWorksImg4 />
              </Col>
              <Col
                sm={24}
                md={24}
                style={{
                  margin: "auto",
                  marginTop: "4em",
                  textAlign: "center",
                }}
              >
                <p
                  className="Mulish"
                  style={{
                    fontSize: 16,
                    lineHeight: "1.5em",
                    color: "black",
                  }}
                >
                  <strong>Please Note:</strong>
                  <br />
                  Your ClaimsCard can only be used at specific retailers as
                  approved for your claim.
                  <br />
                  Should you experience any trouble when using ClaimsCard please
                  ask for the terminal id and phone us on: <br />
                  <a href="tel:+27861252467" style={{ color: "black" }}>
                    0861 CLAIMS (0861252467)
                  </a>
                </p>{" "}
              </Col>
            </Row>
          </div>
        </Element>
        <Element name="retailers" className="element">
          <Row
            id="retailers"
            style={{
              maxWidth: "100vw",
              margin: "auto",

              backgroundImage: `url(${GalleryHomeBack})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundColor: "#4A84B8",
              backgroundRepeat: "no-repeat",

              height: "37em",
            }}
          >
            <Col
              className="gallery-paragraph"
              sm={24}
              md={10}
              style={{ margin: "auto" }}
            >
              <GalleryParagraph />
            </Col>{" "}
          </Row>{" "}
        </Element>
        <Element name="contact-us" className="element">
          <div
            style={{
              height: "7em",
              marginTop: "-2em",
              marginTop: "0em",
              paddingBottom: "6em",
            }}
          ></div>
          <Row
            style={{
              maxWidth: "50em",
              margin: "auto",
              textAlign: "center",
            }}
          >
            <Col sm={24} md={24}>
              <ContactHeader />
            </Col>
          </Row>
          <Row
            style={{
              maxWidth: "50em",
              margin: "auto",
              textAlign: "center",
              height: "25em",
              marginTop: "2em",
            }}
          >
            <Col sm={24} md={24}>
              <Form
                onFinish={this.handleSubmit}
                initialValues={{
                  remember: true,
                }}
                name="wrap"
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
              >
                <Row style={{}}>
                  <Col sm={24} md={11}>
                    <Form.Item
                      name="nameMobile"
                      rules={[{ required: true }]}
                      hasFeedback
                    >
                      <StyledInput placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col sm={0} md={2}></Col>
                  <Col sm={24} md={11}>
                    <Form.Item
                      name="emailMobile"
                      rules={[{ type: "email", required: true }]}
                      hasFeedback
                    >
                      <StyledInput placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={24}>
                    <Form.Item
                      name="messageMobile"
                      rules={[{ required: true }]}
                      hasFeedback
                    >
                      <TextArea rows={6} placeholder="Message" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button
                    style={{
                      borderRadius: 50,
                      fontWeight: 500,
                      height: "2em",
                      width: "15em",
                      fontSize: "17px",
                      backgroundColor: "#4A84B8",
                      border: "black",
                      marginTop: "-1em",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>

            <img
              className="contact-image"
              style={{
                marginTop: "-18em",
                width: "100vw",
                left: 0,
                height: "70em",
                objectFit: "cover",
                bottom: 0,
                zIndex: -2,
                position: "fixed",
              }}
              src={ContactBack}
            ></img>
            <Col span={24}>
              <p
                className="Mulish"
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  marginLeft: "-3em",
                  marginTop: "1.5rem",
                  color: "white",
                }}
              >
                <a
                  href="mailto:support@claimscard.co.za"
                  style={{ color: "white" }}
                >
                  support@claimscard.co.za
                </a>
                <img
                  src={VertiLine}
                  style={{
                    height: "2em",
                    marginRight: "0.2em",
                    marginLeft: "0.2em",
                    marginTop: "-0.3em",
                  }}
                ></img>
                <a href="tel:+27861252467" style={{ color: "white" }}>
                  Tel: 086 125 2467
                </a>
              </p>{" "}
            </Col>
          </Row>{" "}
        </Element>
        <SuccessModal
          open={this.state.successModal}
          onClose={this.handleCloseSuccessModal}
          center
        >
          <img src={LogoModal} alt="Logo" style={{ width: "12em" }} />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
            }}
          >
            Thank you for contacting
            <br />
            <span style={{ color: "#4A84B8" }}>ClaimsCard.</span> <br />
            <span style={{ color: "black " }}>
              We will be in touch shortly.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
          <Button
            onClick={this.handleCloseSuccessModal}
            style={{
              margin: "auto",
              height: "2.9em",
              fontFamily: "Nunito",
              fontSize: "1rem",
              fontWeight: 500,
              borderRadius: "5em",
              backgroundColor: "#4A84B8",
              border: "none",
              marginBottom: "1em",
              paddingLeft: "3em",
              paddingRight: "3em",
              marginRight: "0em",
              textShadow: "none",
            }}
            type="primary"
          >
            Close
          </Button>{" "}
        </SuccessModal>
      </div>
    );
  }
}

export default Home;
