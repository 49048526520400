import React, { useState } from "react";
import ReactDOM from "react-dom";
import { PageHeader, Menu, Button, Dropdown, Badge, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { slide as Burger, SubMenu, Item } from "burger-menu";
import "burger-menu/lib/index.css";
import { DownOutlined } from "@ant-design/icons";
import MainLogo from "../static/Home/main-logo.svg";
import "antd/dist/antd.css";

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div id="nav-toggle" onClick={() => setIsOpen(!isOpen)}>
        <span></span>
      </div>
      <Burger
        left
        isOpen={isOpen}
        selectedKey={"entry"}
        onClose={() => setIsOpen(false)}
      >
        <Link to="/">
          <img
            style={{ width: "13em", marginBottom: "1em" }}
            src={MainLogo}
          ></img>{" "}
        </Link>
        <br />
        <a href="#home" className="mobile-display">
          <Button
            onClick={() => setIsOpen(false)}
            style={{
              color: "white",
              marginTop: "1.6em",
              backgroundColor: "transparent",
              fontFamily: "Raleway",
              border: "none",
              boxShadow: "none",
              fontWeight: 700,
              fontSize: "14px",
              width: "10em",
            }}
          >
            Home
          </Button>
        </a>
        <br />
        <a href="#about" className="mobile-display">
          <Button
            onClick={() => setIsOpen(false)}
            style={{
              color: "white",
              marginTop: "1.6em",
              backgroundColor: "transparent",
              fontFamily: "Raleway",
              border: "none",
              boxShadow: "none",
              fontWeight: 700,
              fontSize: "14px",
              width: "10em",
            }}
          >
            About
          </Button>
        </a>
        <br />
        <a href="#how-it-works" className="mobile-display">
          <Button
            onClick={() => setIsOpen(false)}
            style={{
              color: "white",
              marginTop: "1.6em",
              backgroundColor: "transparent",
              fontFamily: "Raleway",
              border: "none",
              boxShadow: "none",
              fontWeight: 700,
              fontSize: "14px",
              width: "10em",
            }}
          >
            How it works
          </Button>
        </a>
        <br />
        <a href="#retailers" className="mobile-display">
          <Button
            onClick={() => setIsOpen(false)}
            style={{
              color: "white",
              marginTop: "1.6em",
              backgroundColor: "transparent",
              fontFamily: "Raleway",
              border: "none",
              boxShadow: "none",
              fontWeight: 700,
              fontSize: "14px",
              width: "10em",
            }}
          >
            Retailers
          </Button>
        </a>
        <br />
        <a href="#contact-us" className="mobile-display">
          <Button
            onClick={() => setIsOpen(false)}
            style={{
              color: "white",
              marginTop: "1.6em",
              backgroundColor: "transparent",
              fontFamily: "Raleway",
              border: "none",
              boxShadow: "none",
              fontWeight: 700,
              fontSize: "14px",
              width: "10em",
            }}
          >
            Contact Us
          </Button>
        </a>
      </Burger>
    </>
  );
};
const MainHeader = () => (
  <div
    id="Header"
    className="site-page-header-ghost-wrapper"
    style={{
      backgroundColor: "#00468E",
      position: "fixed",
      zIndex: 9,
      width: "100vw",
    }}
  >
    <PageHeader
      style={{
        backgroundColor: "#00468E",
        maxWidth: "100em",
        width: "100vw",
        margin: "auto",
        padding: 0,
      }}
      ghost={false}
      //  onBack={() => window.history.back()}
      title={
        <div>
          <Link to="/">
            <img
              className="header-logo"
              style={{ width: "5em" }}
              src={MainLogo}
            ></img>{" "}
          </Link>
          <div class="main">
            <div class="circle"></div>
          </div>
        </div>
      }
      //  subTitle="This is a subtitle"
      extra={[
        <BurgerMenu />,
        <a href="#home" className="desktop-display">
          <Button
            style={{
              color: "white",
              marginTop: "1.6em",
              backgroundColor: "transparent",
              fontFamily: "Raleway",
              border: "none",
              boxShadow: "none",
              fontWeight: 700,
              fontSize: "14px",
              width: "10em",
            }}
          >
            Home
          </Button>
        </a>,
        <a href="#about" className="desktop-display">
          <Button
            style={{
              color: "white",
              marginTop: "1.6em",
              backgroundColor: "transparent",
              fontFamily: "Raleway",
              border: "none",
              boxShadow: "none",
              fontWeight: 700,
              fontSize: "14px",
              width: "10em",
            }}
          >
            About
          </Button>
        </a>,
        <a href="#how-it-works" className="desktop-display">
          <Button
            style={{
              color: "white",
              marginTop: "1.6em",
              backgroundColor: "transparent",
              fontFamily: "Raleway",
              border: "none",
              boxShadow: "none",
              fontWeight: 700,
              fontSize: "14px",
              width: "10em",
            }}
          >
            How it works
          </Button>
        </a>,
        <a href="#retailers" className="desktop-display">
          <Button
            style={{
              color: "white",
              marginTop: "1.6em",
              backgroundColor: "transparent",
              fontFamily: "Raleway",
              border: "none",
              boxShadow: "none",
              fontWeight: 700,
              fontSize: "14px",
              width: "10em",
            }}
          >
            Retailers
          </Button>
        </a>,
        //<Button
        //  style={{
        //    color: "black",
        //    marginTop: "1.6em",
        //    border: "none",
        //    boxShadow: "none",
        //    fontWeight: 700,
        //    fontSize: "17px",
        //  }}
        //>
        //  <Dropdown overlay={menu}>
        //    <a
        //      className="ant-dropdown-link"
        //      onClick={(e) => e.preventDefault()}
        //    >
        //      Products <DownOutlined />
        //    </a>
        //  </Dropdown>
        //</Button>,
        <a href="#contact-us" className="desktop-display">
          <Button
            style={{
              color: "white",
              marginTop: "1.6em",
              backgroundColor: "transparent",
              fontFamily: "Raleway",
              border: "none",
              boxShadow: "none",
              fontWeight: 700,
              fontSize: "14px",
              width: "10em",
            }}
          >
            Contact Us
          </Button>
        </a>,
      ]}
    >
      {/*<Descriptions size="small" column={3}>
         <Descriptions.Item label="Created">Lili Qu</Descriptions.Item>
         <Descriptions.Item label="Association">
           <a>421421</a>
         </Descriptions.Item>
         <Descriptions.Item label="Creation Time">2017-01-10</Descriptions.Item>
         <Descriptions.Item label="Effective Time">2017-10-10</Descriptions.Item>
         <Descriptions.Item label="Remarks">
           Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
         </Descriptions.Item>
       </Descriptions>*/}
    </PageHeader>
  </div>
);

const Header = () => (
  <header className="Homepage">
    <MainHeader />
    {/* <div style={{ position: "absolute" }}>
      <a href="#home" className="mobile-display">
        <Tooltip title="Home" placement="left" color="#4A84B8">
          <Badge
            color="#4A84B8"
            style={{
              position: "fixed",
              right: "1em",
              top: "46vh",
            }}
          ></Badge>
        </Tooltip>
      </a>

      <br />
      <a href="#about" className="mobile-display">
        <Tooltip title="About" placement="left" color="#4A84B8">
          <Badge
            color="#4A84B8"
            style={{
              position: "fixed",
              right: "1em",
              top: "49vh",
            }}
          ></Badge>
        </Tooltip>
      </a>
      <a href="#how-it-works" className="mobile-display">
        <Tooltip title="How it works" placement="left" color="#4A84B8">
          <Badge
            color="#4A84B8"
            style={{
              position: "fixed",
              right: "1em",
              top: "52vh",
            }}
          ></Badge>
        </Tooltip>
      </a>
      <a href="#retailers" className="mobile-display">
        <Tooltip title="Retailers" placement="left" color="#4A84B8">
          <Badge
            color="#4A84B8"
            style={{
              position: "fixed",
              right: "1em",
              top: "55vh",
            }}
          ></Badge>
        </Tooltip>
      </a>
      <a href="#contact-us" className="mobile-display">
        <Tooltip title="Contact Us" placement="left" color="#4A84B8">
          <Badge
            color="#4A84B8"
            style={{
              position: "fixed",
              right: "1em",
              top: "58vh",
            }}
          ></Badge>
        </Tooltip>
      </a>
    </div> */}
  </header>
);

export default Header;
